import React, { useCallback, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import Carousel from "react-elastic-carousel";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Link } from "gatsby";
import MainLayout from "../components/MainLayout";
import Testimonial from "../components/Testimonial/Testimonial";

interface CaseStudyProps {}

const caseStudyMainImage = "/images/showcase/feature-interactivity.png";
const caseStudyCompanyLogo = "images/logos/Spinny_Logo.jpg";
const testimonials = [
  {
    title: "Scaling with HelloAR!",
    description:
      "HelloAR’s 360 immersive photography has added a new dimension to online used car sales. The immersive catalogue has led to happier customers making quicker purchasing decisions. Look forward to scaling this up with HelloAR!",
    name: "Naveen Sarawgi",
    designation: "CEO, Gigacars",
    profileWebp: "../../images/testimonials/naveen-gigacars1.webp",
    profileJpg: "../../images/testimonials/naveen-gigacars.jpg",
  },
  {
    title: "Exicting shopping experience",
    description:
      "When it comes to shopping for handicrafts, customers want to gauge every minute detail possible. With HelloAR, we're pretty close to enabling the same, thus making the entire shopping experience more exciting and thoughtful at the same time.",
    name: "Akansha",
    designation: "CE0, Craft Maestros",
    profileWebp: "../../images/testimonials/akansha-craftmaestro1.webp",
    profileJpg: "../../images/testimonials/akansha-craftmaestro.jpg",
  },
  {
    title: "Amazing platform",
    description:
      "HelloAR is an amazing platform. HelloAR's 3D images have aced up our ecommerce & engagement game. Also, the team is very helpful and nice.",
    name: "Aishwarya",
    designation: "Codesustain",
    profileWebp: "../../images/testimonials/pratima-codesustain1.webp",
    profileJpg: "../../images/testimonials/pratima-codesustain.jpg",
  },
  {
    title: "Products look exceptionally good",
    description:
      "Fantastic job on the chairs! All are looking amazing. Products look exceptionally good. Very life like. Good job team!",
    name: "Kiran",
    designation: "Featherlite",
    profileWebp: "../../images/testimonials/kiran-featherlite2.webp",
    profileJpg: "../../images/testimonials/kiran-featherlite.webp",
  },
];

const CaseStudy = () => {
  const carouselRef = useRef<any | null>(null);
  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const disableNext = testimonialIndex === testimonials.length - 1;
  const disablePrevious = testimonialIndex === 0;
  const handleNext = useCallback(() => {
    carouselRef.current?.slideNext();
    setTestimonialIndex((prev) => prev + 1);
  }, []);
  const handlePrevious = useCallback(() => {
    carouselRef.current?.slidePrev();
    setTestimonialIndex((prev) => prev - 1);
  }, []);

  const VideoComponent: React.FC = () => {
    return (
      <div
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 aspect ratio */,
          overflow: "hidden",
          flex: 1,
          display: "flex",
        }}
      >
      <video
        autoPlay
        muted
        style={{
            position: "absolute",
            top: 0, //"20%",
            left: 0,
            width: "100%",
            alignSelf: "center",
            height: "100%",
          }}
        >
          <source src="/videos/casestudies/spinny-final-web.mp4" />
        </video>
      </div>
    );
  };

  return (
    <MainLayout hideFooter width={0}>
      <div className="case-study-wrapper bg-white pointer-events-all">
        <Container>
          <div className="case-study-intro d-flex">
            {/* <img src={caseStudyMainImage} alt="case-study-image" /> */}
            <VideoComponent />
            <div className="case-study-intro-right">
              <img src={caseStudyCompanyLogo} alt="case-study-company-logo" />
              <h2>What made Spinny different from its Competitors?</h2>

              <div>
                <h6 className="text-uppercase">What we did?</h6>
                <p>
                  Spinny utilized the powerful capabilities of HelloAR tools to
                  elevate their ordinary 2D picture gallery into an immersive
                  360-degree viewing experience. Customers can now to explore
                  every intricate detail and hidden nook of the car, enabling
                  Spinny to provide complete transparency and empower customers
                  with a comprehensive understanding of the car.
                </p>
              </div>
              <div>
                <h6 className="text-uppercase">How are they benefiting?</h6>
                <p>
                  This helps Spinny build trust and confidence in their brand,
                  as a result customers are more likely to be satisfied with
                  their purchase, leading to increased customer satisfaction.
                  Additionally, the immersive viewing experience sets Spinny
                  apart from their competitors and positions them as a
                  forward-thinking and innovative company in the automotive
                  industry. Check out their website - https://www.spinny.com/
                </p>
              </div>
              <div style={{ width: "max-content" }}>
                <Link to="/contact-us">
                  <button className="button-primary w-100 mb-3">
                    Book Demo
                  </button>
                </Link>
                <Link to="/case-studies">
                  <button className="button-secondary d-flex align-items-center">
                    <div className="mr-2">See more cases</div>
                    <AiOutlineArrowRight />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="testimonial-wrapper mt-5">
            <h1>Testimonials</h1>
            <div className="testimonial-container d-flex">
              <div className="testimonial-list flex-grow-1">
                <TestimonialCarouselMemo ref={carouselRef} />
              </div>
              <div className="testimonial-slide-wrapper d-flex align-items-center">
                <button onClick={handlePrevious} disabled={disablePrevious}>
                  <BsChevronLeft size={20} />
                </button>
                <div className="testimonial-slide-text">Slide</div>
                <button onClick={handleNext} disabled={disableNext}>
                  <BsChevronRight size={20} />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </MainLayout>
  );
};

interface TestimonialCarouselProps {}
const TestimonialCarousel = React.forwardRef<any, TestimonialCarouselProps>(
  (_, ref) => {
    return (
      <Carousel
        initialActiveIndex={0}
        renderPagination={() => <></>}
        itemPadding={[]}
        transitionMs={200}
        ref={ref}
        breakPoints={[
          { width: 320, itemsToShow: 1, itemsToScroll: 1 },
          { width: 620, itemsToShow: 1, itemsToScroll: 1 },
        ]}
        showArrows={false}
      >
        {testimonials.map((testimonial) => (
          <Testimonial
            {...testimonial}
            customerPhoto={testimonial.profileJpg}
          />
        ))}
      </Carousel>
    );
  }
);

const TestimonialCarouselMemo = React.memo(TestimonialCarousel);

CaseStudy.Layout = MainLayout;

export default CaseStudy;
